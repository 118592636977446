

<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<button @click="showAddEdit()" class="pull-right btn btn-success"><i class="fa fa-plus"></i></button>
			<button @click="loadLazyData" style="margin-right: 6px;" class="pull-right btn btn-info"
				title="Reload listing"><i class="fa fa-refresh"></i></button>
			<div class="pull-right" style="margin-top: 10px; margin-right: 15px">Last Update: {{ format(refreshTime,
					"hh:mma")
			}}</div>
			<div class="title text-success">Manage Pulmonology Reports</div>
		</div>
		<DataTable stripedRows :value="tableRecords" :lazy="true" :resizableColumns="true" columnResizeMode="fit"
			:paginator="true" :rows="100" v-model:filters="filters" ref="dt" :totalRecords="totalRecords"
			:rowsPerPageOptions="[100, 200, 500, 1000]" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
			@filter="onFilter($event)" filterDisplay="row" responsiveLayout="scroll">


			<Column field="id" header="Case ID" filterField="id" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Id" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<span style="border: 1px solid gray; border-radius: 6px; padding: 4px; font-weight: bold; ">{{ data['id'] }}</span>
					</div>
				</template>
			</Column>
			<Column field="patient_name" header="Patient Name" filterField="patient_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Patient Name" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<b>{{ data['patient_name'] }}</b>
					</div>
				</template>
			</Column>

			
			<Column field="reg_no" header="Reg No" filterField="reg_no" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Reg No" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['reg_no'] }}
					</div>
				</template>
			</Column>
			
			<Column field="assign_doc_name" header="Pulmonologist" filterField="assign_doc_name" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Assign To Doctor" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						{{ data['assign_doc_name'] ? data['assign_doc_name'] : "Any Doctor" }}
					</div>
				</template>
			</Column>
			
			<Column field="created_at" header="Created" filterField="created_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Created At" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata" style="width: 65px; margin: auto;">
						{{ format(data['created_at'], "DD/MM/YY h:mmA") }}
					</div>
				</template>
			</Column>
			<Column field="reported_at" header="Reported" filterField="reported_at" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Reported at" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<div v-if="data['reported_at']" style="width: 65px; margin: auto;">
							{{ format(data['reported_at'], "DD/MM/YY h:mmA") }}
						</div>
					</div>
				</template>
			</Column>
			<Column field="status" header="Status" filterField="status" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Status" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata">
						<div v-if="data['status'] == 'pending'">
							<span class="form-control p-0 w-75 text-center"
								style="background: #fd6d7b; color: #fff; padding: 5px; border-radius: 5px;">Pending</span>
						</div>
						<div v-else-if="data['status'] == 'review'">
							<span class="form-control p-0 w-75 text-center"
								style="background: blue; color: #fff; padding: 5px; border-radius: 5px;">Review</span>
						</div>
						<div v-else>
							<select style="border-radius: 4px;" @change="changeStatus(data)" v-model="data['status']"
								class="form-control p-0 w-75 text-center" :class="{ 'bg-warning': data['status'] == 'reported' }"
								:style="{ background: data['status'] == 'printed' ? '#23d84a' : 'white' }">
								<option style="background: #fff;" :selected="data['status'] == 'reported'" value="reported">
									Reported</option>
								<option style="background: #fff;" :selected="data['status'] == 'printed'" value="printed">Printed
								</option>
							</select>
						</div>
					</div>
				</template>
			</Column>
			<Column field="urgent" header="Urgent" filterField="urgent" :sortable="true">
				<template #filter="{ filterModel, filterCallback }">
					<div>
						<input type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="form-control"
							placeholder="Urgent (y,n)" />
					</div>
				</template>
				<template #body="{ data }">
					<div class="tdata text-center">
						<div v-if="data['status'] == 'pending'">
							<Checkbox @change="switchUrgent(data)" v-model="data['urgent']" :binary="true" />
						</div>
					</div>
				</template>
			</Column>
			<Column field="review_notes" header="Review" filterField="review_notes" :sortable="false">
				<template #body="{ data }">
					<div class="tdata">
						<div v-if="data['status'] == 'reported' || data['status'] == 'review'">
							<div v-if="data.review_done == 'n'" :title="data['review_notes']">
								<InputSwitch @change="data['show_review_comment'] = true" :disabled="data['is_review']"
									v-model="data['is_review']" />
								<div v-if="data['show_review_comment']">
									<textarea v-model="data['review_notes']" placeholder="Review Comment To Doctor"></textarea>
									<button type="button" @click="sendReview(data)"
										class="btn btn-sm sm-btn btn-primary">Save</button>
								</div>
							</div>
							<div v-if="data.review_done == 'y'">
								<i style="font-size: 30px" class="fa fa-thumbs-up text-success"></i>
							</div>
						</div>
					</div>
				</template>
			</Column>
			<Column
				header="Options &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;">
				<template #body="{ data }">
					<div class="tdata options">
						<button class="btn btn-sm btn-outline-info" :style="{ color: data.notes ? 'red' : '' }"
							@click="showNotesBox(data)"><i class="fa fa-sticky-note"></i></button>
						<button class="btn btn-sm btn-outline-warning" @click="showAddEdit(data)">
							<i class="fa fa-edit"></i>
						</button>
						<button v-if="data.status == 'reported'" title="Print" type="button" class="btn btn-sm btn-outline-success" @click="printReport(data)">
							<i class="fa fa-print"></i>
						</button>
						<a v-if="data.mobile_no && (data.status == 'reported' || data.status == 'printed')"
							class="btn btn-sm btn-outline-info ms-1"
							:href="'https://api.whatsapp.com/send?phone=91' + (data.mobile_no.substr(-10)) + '&text=' + encodeURIComponent(apiOrigin + '/print-pft-report?data=' + encodeBase64(data.center_id+','+data.id) )"
							target="_blank">
							<i class="fa fa-whatsapp"></i>
						</a>
					</div>
				</template>
			</Column>
		</DataTable>

		<div class="modal fade" ref="notesModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-info text-white">
						<h5 class="modal-title">Notes</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div>
						<textarea v-model="notes" rows="6" class="form-control"></textarea>
					</div>
					<div class="modal-footer">
						<button type="button" @click="saveNotes" class="btn btn-primary">Save</button>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" ref="deleteModal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header bg-danger text-white">
						<h5 class="modal-title">Delete Confirmation</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body bg-white text-body text-center font-weight-bold font-1xl">
						<div class="text-danger" style="font-weight: bold; text-align: left; margin-bottom: 8px;">Record ID:
							{{ editId }}</div>
						You are about to delete this record, Are you sure?
						<br>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-danger" @click="deleteRecord">Delete</button>
					</div>
				</div>
			</div>
		</div>

		<form action="" @submit.prevent="putRecord">
			<div class="modal fade" id="exampleModal" ref="addModal" tabindex="-1" aria-labelledby="exampleModalLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title text-success" id="exampleModalLabel">
								<div v-if="editId == 0">Add Patient (Pulmonology)</div>
								<div v-else>Edit Patient</div>
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="container">
								<div class="row">
									<div class="col-sm-6">
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.patient_name?.hasError && fcontrol.patient_name.touched }"
												v-model="fdata.patient_name" @blur="fcontrol.patient_name.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.patient_name?.hasError">
												<span v-if="fcontrol.patient_name.errors.required">You must enter patient
													name</span>
												<span v-if="fcontrol.patient_name.errors.max">Patient Name can contain maximum
													{{ fcontrol.patient_name.errors.max.maxLength }} characters</span>
											</div>
											<label>Patient Name</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.patient_age?.hasError && fcontrol.patient_age.touched }"
												v-model="fdata.patient_age" @blur="fcontrol.patient_age.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.patient_age?.hasError">
												<span v-if="fcontrol.patient_age.errors.required">You must enter patient age</span>
												<span v-if="fcontrol.patient_age.errors.max">Patient Age can contain maximum
													{{ fcontrol.patient_age.errors.max.maxLength }} characters</span>
											</div>
											<label>Patient Age</label>
										</div>
										<div class="form-group ui-float-label">
											<select class="form-control"
												:class="{ 'is-invalid': fcontrol.patient_gender?.hasError && fcontrol.patient_gender.touched }"
												v-model="fdata.patient_gender" @blur="fcontrol.patient_gender.touched = true">
												<option value="" disabled selected>Select Patient Gender</option>
												<option value="male">Male</option>
												<option value="female">Female</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.patient_gender?.hasError">
												<span v-if="fcontrol.patient_gender.errors.required">You must enter patient
													gender</span>
												<span v-if="fcontrol.patient_gender.errors.in">Invalid patient gender</span>
											</div>
											<label>Patient Gender</label>
										</div>
										<div class="form-group ui-float-label">
											<div class="p-fluid">
												<AutoComplete placeholder="Referral Doctor" v-model="fdata.ref_doctor_name"
													:suggestions="refDoctors" @complete="searchRefDoctor($event)" field="name" />
											</div>
											<div class="invalid-feedback" :style="{ display: refDoctorInvalid ? 'block' : 'none' }">
												<span>You must enter Referral doctor</span>
											</div>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.bp_systole?.hasError && fcontrol.bp_systole.touched }"
												v-model="fdata.bp_systole" @blur="fcontrol.bp_systole.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.bp_systole?.hasError">
												<span v-if="fcontrol.bp_systole.errors.required">You must enter bp systole</span>
												<span v-if="fcontrol.bp_systole.errors.max">Bp Systole can contain maximum
													{{ fcontrol.bp_systole.errors.max.maxLength }} characters</span>
											</div>
											<label>Bp Systole (Optional)</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.bp_diastole?.hasError && fcontrol.bp_diastole.touched }"
												v-model="fdata.bp_diastole" @blur="fcontrol.bp_diastole.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.bp_diastole?.hasError">
												<span v-if="fcontrol.bp_diastole.errors.required">You must enter bp diastole</span>
												<span v-if="fcontrol.bp_diastole.errors.max">Bp Diastole can contain maximum
													{{ fcontrol.bp_diastole.errors.max.maxLength }} characters</span>
											</div>
											<label>Bp Diastole (Optional)</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.spo2?.hasError && fcontrol.spo2.touched }"
												v-model="fdata.spo2" @blur="fcontrol.spo2.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.spo2?.hasError">
												<span v-if="fcontrol.spo2.errors.required">You must enter spo2</span>
												<span v-if="fcontrol.spo2.errors.max">Spo2 can contain maximum
													{{ fcontrol.spo2.errors.max.maxLength }} characters</span>
											</div>
											<label>Spo2 (Optional)</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.pulse_rate?.hasError && fcontrol.pulse_rate.touched }"
												v-model="fdata.pulse_rate" @blur="fcontrol.pulse_rate.touched = true"
												placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.pulse_rate?.hasError">
												<span v-if="fcontrol.pulse_rate.errors.required">You must enter pulse rate</span>
												<span v-if="fcontrol.pulse_rate.errors.max">Pulse rate can contain maximum
													{{ fcontrol.pulse_rate.errors.max.maxLength }} characters</span>
											</div>
											<label>Pulse Rate (Optional)</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.height?.hasError && fcontrol.height.touched }"
												v-model="fdata.height" @blur="fcontrol.height.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.height?.hasError">
												<span v-if="fcontrol.height.errors.required">You must enter height</span>
												<span v-if="fcontrol.height.errors.max">Height can contain maximum
													{{ fcontrol.height.errors.max.maxLength }} characters</span>
											</div>
											<label>Height (Optional)</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.weight?.hasError && fcontrol.weight.touched }"
												v-model="fdata.weight" @blur="fcontrol.weight.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.weight?.hasError">
												<span v-if="fcontrol.weight.errors.required">You must enter weight</span>
												<span v-if="fcontrol.weight.errors.max">Weight can contain maximum
													{{ fcontrol.weight.errors.max.maxLength }} characters</span>
											</div>
											<label>Weight (Optional)</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.symptoms?.hasError && fcontrol.symptoms.touched }"
												v-model="fdata.symptoms" @blur="fcontrol.symptoms.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.symptoms?.hasError">
												<span v-if="fcontrol.symptoms.errors.required">You must enter symptoms</span>
											</div>
											<label>Symptoms</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="date" class="form-control"
												:class="{ 'is-invalid': fcontrol.test_date?.hasError && fcontrol.test_date.touched }"
												v-model="fdata.test_date" @blur="fcontrol.test_date.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.test_date?.hasError">
												<span v-if="fcontrol.test_date.errors.required">You must enter test date</span>
											</div>
											<label>Test Date</label>
										</div>
										<div class="form-group ui-float-label">
											<textarea rows="2" :disabled="editRow?.status == 'reported'" class="form-control"
												:class="{ 'is-invalid': fcontrol.history?.hasError && fcontrol.history.touched }"
												v-model="fdata.history" @blur="fcontrol.history.touched = true"
												placeholder="History"></textarea>
											<div class="invalid-feedback" v-if="fcontrol.history?.hasError">
												<span v-if="fcontrol.history.errors.required">You must enter history</span>
											</div>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.reg_no?.hasError && fcontrol.reg_no.touched }"
												v-model="fdata.reg_no" @blur="fcontrol.reg_no.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.reg_no?.hasError">
												<span v-if="fcontrol.reg_no.errors.required">You must enter reg no</span>
												<span v-if="fcontrol.reg_no.errors.max">Reg No can contain maximum
													{{ fcontrol.reg_no.errors.max.maxLength }} characters</span>
											</div>
											<label>Reg No/ Bill No</label>
										</div>
										<div class="form-group ui-float-label">
											<input type="text" class="form-control"
												:class="{ 'is-invalid': fcontrol.mobile_no?.hasError && fcontrol.mobile_no.touched }"
												v-model="fdata.mobile_no" @blur="fcontrol.mobile_no.touched = true" placeholder=" " />
											<div class="invalid-feedback" v-if="fcontrol.mobile_no?.hasError">
												<span v-if="fcontrol.mobile_no.errors.required">You must enter WhatsApp
													number</span>
												<span v-if="fcontrol.mobile_no.errors.max">WhatsApp number can contain maximum
													{{ fcontrol.mobile_no.errors.max.maxLength }} characters</span>
											</div>
											<label>WhatsApp Number</label>
										</div>

										<div class="form-group ui-float-label">
											<select class="form-control"
												:disabled="(editRow && editRow?.status != 'pending') || editRow?.accepted_at"
												:class="{ 'is-invalid': fcontrol.assign_doc_id?.hasError && fcontrol.assign_doc_id.touched }"
												v-model="fdata.assign_doc_id" @blur="fcontrol.assign_doc_id.touched = true">
												<option value="0">Any Available Doctors</option>
												<option v-for="d in pulmonologists" :key="d.id" :value="d.id">{{ d.name }}</option>
											</select>
											<div class="invalid-feedback" v-if="fcontrol.assign_doc_id?.hasError">
												<span v-if="fcontrol.assign_doc_id.errors.required">You must enter assign to
													doctor</span>
											</div>
											<label>Assign To Doctor</label>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="form-group">
											<input type="file" id="image" :disabled="editRow?.status == 'reported'"
												accept="image/*,application/pdf" multiple style="display: none" class="form-control"
												@change="selectImages()" ref="images" />
											<label for="image" style="display: block">
												<div class="form-control">Select PFT Files</div>
											</label>
										</div>
										<div class="images">
											<div v-for="image in images" :key="image">
												<i v-if="!(editRow?.status == 'reported')" @click="removeImage(image)"
													class="fa fa-close text-danger"></i>
												<img v-if="!image.isPdf" :src="image.url" alt="">
												<embed v-else :src="image.url" style="width: 100%; height: 475px;"
													type="application/pdf">
											</div>
										</div>
									</div>
									<div class="col-sm-12" v-if="saving">
										<ProgressBar :value="uploadPercentage">
											Sending: {{ uploadPercentage }}%
										</ProgressBar>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
							<button type="submit" class="btn btn-primary">Save</button>
						</div>
					</div>
				</div>
			</div>
		</form>

	</div>
</template>

<script>
import ProgressBar from 'primevue/progressbar';

import { Modal } from 'bootstrap'
import AutoComplete from 'primevue/autocomplete';
import Toast from 'primevue/toast';
import InputSwitch from 'primevue/inputswitch';
import Checkbox from 'primevue/checkbox';

export default {
	components: {
		AutoComplete,
		Toast,
		InputSwitch,
		Checkbox,
		ProgressBar
	},
	data() {
		return {
			loading: false,
			totalRecords: 0,
			tableRecords: null,
			editId: 0,
			editRow: null,
			saving: false,
			uploadPercentage: 0,
			addModal: null,
			deleteModal: null,
			notesModal: null,
			lazyParams: {},
			refDoctors: null,
			tests: [],
			filteredTests: null,
			pulmonologists: [],
			images: [],
			/*
				 {
					  url: 
					  name:
					  file: 
				 }
			*/
			removedImages: [],
			refDoctorInvalid: false,
			testNameInvalid: false,
			center: {},
			refreshTime: new Date(),
			notes: '',
			filters: {
				'id': { value: '', matchMode: 'like' },
				'patient_name': { value: '', matchMode: 'like' },
				'patient_age': { value: '', matchMode: 'like' },
				'patient_gender': { value: '', matchMode: 'like' },
				'ref_doctor_name': { value: '', matchMode: 'like' },
				'bp_systole': { value: '', matchMode: 'like' },
				'bp_diastole': { value: '', matchMode: 'like' },
				'spo2': { value: '', matchMode: 'like' },
				'height': { value: '', matchMode: 'like' },
				'weight': { value: '', matchMode: 'like' },
				'symptoms': { value: '', matchMode: 'like' },
				'test_name': { value: '', matchMode: 'like' },
				'test_date': { value: '', matchMode: 'like' },
				'reported_at': { value: '', matchMode: 'like' },
				'created_at': { value: '', matchMode: 'like' },
				'history': { value: '', matchMode: 'like' },
				'reg_no': { value: '', matchMode: 'like' },
				'mobile_no': { value: '', matchMode: 'like' },
				'assign_doc_name': { value: '', matchMode: 'like' },
				'status': { value: '', matchMode: 'like' },
				'urgent': { value: '', matchMode: 'like' },
				'review_notes': { value: '', matchMode: 'like' },
			},
			fdata: {
				id: '',
				patient_name: '',
				patient_age: '',
				patient_gender: '',
				ref_doctor_name: '',
				bp_systole: '',
				bp_diastole: '',
				spo2: '',
				pulse_rate: '',
				height: '',
				weight: '',
				symptoms: '',
				test_id: '',
				test_date: '',
				history: '',
				reg_no: '',
				mobile_no: '',
				assign_doc_id: '0',
			},
			fvalidator: {
				patient_name: 'required|max:30',
				patient_age: 'required|max:15',
				patient_gender: 'required|in:male,female',
				ref_doctor_name: 'required',
				bp_systole: 'max:255',
				bp_diastole: 'max:255',
				spo2: 'max:255',
				pulse_rate: 'max:20',
				height: 'max:255',
				weight: 'max:255',
				symptoms: 'required',
				// test_id: 'required',
				test_date: 'required',
				history: 'required',
				reg_no: 'max:40',
				mobile_no: 'max:15',
				assign_doc_id: 'required',
			}
		}
	},
	mounted() {
		this.addModal = new Modal(this.$refs.addModal, { backdrop: 'static', keyboard: false });
		this.deleteModal = new Modal(this.$refs.deleteModal);
		this.notesModal = new Modal(this.$refs.notesModal);

		this.lazyParams = {
			first: 0,
			rows: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			filters: this.filters,
		};
		this.loadLazyData();

		this.get('center/load-tests').then(res => {
			this.tests = res;
		});
		this.get("center/load-pulmonologists").then(res => {
			this.pulmonologists = res;
		})
		this.get("center/load-center").then(res => {
			this.center = res;
		});
		
		setTimeout(()=>{
			soc.on('pftReport', d=>{
				this.loadLazyData();
				notiAudio.play();
			})
		},0)
	},
	unmounted(){
		soc.off('pftReport');
	},
	methods: {
		encodeURIComponent(data) {
			return window.encodeURIComponent(data)
		},
		encodeBase64(str){
			return btoa(str).replaceAll(/=/ig, "");
		},
		printReport(row){
			
			let data =  btoa(row.center_id+','+row.id).replaceAll(/=/ig, "");

			this.$router.push({
				path: "/print-pft-report",
				query: {
					data
				}
			})
		},
		async sendReview(data) {
			await this.post("center/send-pft-review", data);
			this.loadLazyData();
			this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Review request sent successfully.', life: 3000 });
		},
		showNotesBox(data) {
			this.notes = data.notes;
			this.editRow = data;
			this.notesModal.show();
		},
		saveNotes() {
			this.post("center/save-pft-notes", {
				notes: this.notes,
				report_id: this.editRow.id
			}).then(() => {
				this.notesModal.hide();
				this.loadLazyData();
			})
		},
		selectImages() {
			let files = this.$refs['images'].files;
			for (let i = 0; i < files.length; i++) {
				let img = files[i];

				var reader = new FileReader();
				reader.onload = e => {
					this.images.push({
						url: e.target.result,
						file: img,
						isPdf: img.type == "application/pdf",
					});
				}
				reader.readAsDataURL(img);
			}
			this.$refs['images'].value = '';
		},
		removeImage(image) {
			this.images = this.images.filter(fimg => fimg != image);
			if (image.name) this.removedImages.push(image.name);
		},
		putRecord() {
			if (this.saving) return;
			let hasError = false;
			if (this.fHasError()) hasError = true;

			if (!this.fdata.ref_doctor_name) {
				hasError = true;
				this.refDoctorInvalid = true;
			} else {
				this.refDoctorInvalid = false;
			}
			if (hasError) return;
			if (!this.editId && !this.images.length) return;

			let data = JSON.parse(JSON.stringify(this.fdata));
			if (typeof this.fdata.ref_doctor_name == 'object') data.ref_doctor_name = this.fdata.ref_doctor_name.name;
			this.saving = true;


			let totalNewImages = 0;
			for (let image of this.images) {
				if (image.file) {
					data['image' + totalNewImages] = image.file;
					totalNewImages++;
				}
			}
			data.totalNewImages = totalNewImages;

			if (this.editId) {
				data.id = this.editId;
				data.removedImages = this.removedImages.join(",");
			}
			this.upload("center/put-pft-report", data, (err, res) => {
				this.saving = false;
				if (res.success) {
					this.addModal.hide();
					this.loadLazyData();
					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Report submitted successfully.', life: 3000 });
				} else if (res.lowBalance) {
					this.$toast.add({ severity: 'warn', summary: 'Low Balance', detail: 'You have low balance. Please recharge to continue', life: 3000 });
				} else {
					this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Sorry something went worng, Please try again later.', life: 3000 });
				}
			}, p => {
				this.uploadPercentage = Math.round(p * 100);
			})
		},
		searchRefDoctor(event) {
			if (!event.query.trim().length) {
				this.refDoctors = [];
			} else {
				this.post('center/filter-ref-doctors', { prefix: event.query.toLowerCase() }).then(res => {
					this.refDoctors = res;
				})
			}
		},
		searchTests(event) {
			if (!event.query.trim().length) {
				this.filteredTests = [...this.tests];
			} else {
				this.filteredTests = this.tests.filter((test) => {
					return test.name.toLowerCase().startsWith(event.query.toLowerCase());
				});
			}
		},
		showAddEdit(row) {
			this.freset();
			this.refDoctorInvalid = false;
			this.testNameInvalid = false;

			this.images = [];
			this.removedImages = [];

			this.editId = row ? row.id : 0;
			this.editRow = row;
			if (this.center.prefer_pulmonologist_id) this.fdata.assign_doc_id = this.center.prefer_pulmonologist_id;
			if (row) {
				let images = row.images ? row.images.split(",") : [];
				for (let i of images) {
					this.images.push({
						url: this.apiOrigin + "/" + 'images/pulmonology/' + i,
						name: i,
						isPdf: i.indexOf(".pdf") != -1
					})
				}

				row.assign_doc_id = String(row.assign_doc_id);
				row.test_date = row.test_date.split("T")[0]
				this.fpopulate(row);
				this.fdata.test_id = this.tests.find(r => r.id == row.test_id);
			} else {
				this.fdata.test_date = new Date().toISOString().split("T")[0];
			}
			this.addModal.show();
		},
		async changeStatus(data) {
			await this.post("center/change-pft-status", data);
			this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Status changed successfully', life: 3000 });
		},
		switchUrgent(data) {
			this.post("center/change-pft-urgent", {
				id: data.id,
				urgent: data.urgent
			}).then(res => {
				this.loadLazyData();
				if (res.success) {
					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Urgent status changed.', life: 3000 });
				}
			});
		},
		deleteRecord() {
			this.delete("center/delete-reports/" + this.editId).then(res => {
				this.saving = false;
				if (res.success) {
					this.deleteModal.hide();
					this.loadLazyData();
				}
			});
		},
		loadLazyData() {
			this.loading = true;
			this.refreshTime = new Date();
			let params = JSON.parse(JSON.stringify(this.lazyParams));
			for (let f in params.filters) {
				if (!params.filters[f].value.length) delete params.filters[f];
			}

			if (!params.sortField) {
				params.sortField = "id";
				params.sortOrder = -1;
			}
			params.joins = [
				{
					"fTable": "pulmonologists",
					"fKey": "assign_doc_id",
					"fField": [["name", "assign_doc_name"]]
				}
			]
			this.post("center/load-pft-reports", params).then(res => {
				this.loading = false;
				if (res.success) {
					this.totalRecords = res.totalRecords;

					this.tableRecords = res.rows;
					for (let row of res.rows) {
						row.urgent = row.urgent == 'y';
						row.is_review = row.status == 'review'
					}
				}
			});
		},
		onPage(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onSort(event) {
			this.lazyParams = event;
			this.loadLazyData();
		},
		onFilter() {
			this.loading = true;
			this.lazyParams.filters = this.filters;
			this.loadLazyData();
		}
	}
}
</script>
<style scoped>
.images>div {
	position: relative;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
	margin-bottom: 5px;
}

.images img {
	max-width: 100%;
	margin: auto;
	display: block;
}

.images>div .fa-close {
	font-size: 35px;
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 10px;
}
</style>




